// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// owl carousel style imports..
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

$appreciate-primary: mat-palette($mat-indigo);
$appreciate-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$appreciate-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$appreciate-theme: mat-light-theme(
  (
    color: (
      primary: $appreciate-primary,
      accent: $appreciate-accent,
      warn: $appreciate-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($appreciate-theme);
@import "~animate.css/animate.min.css";
@import '~funnel-graph-js/dist/css/main.min.css';
@import '~funnel-graph-js/dist/css/theme.min.css';

$custom-typography: mat-typography-config(
  $font-family: "Manrope",
);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($appreciate-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

// color codes
$body: #fbfbfb;
$blue: #0461e5;
$pale-blue: #6d9ff7;
$aqua: #3ee3c5;
$pale-aqua: #a2edde;
$chrome: #f9d649;
$negative: #ea3636;
$positive: #00b871;

$indigo: #181636;
$brown: #ae947c;
$black: #000000;
$grey: #a3a2af;
$lightGrey: #a3a2af;
$light: #55555a;
$blue-linear-gradiant: linear-gradient(225deg, #3ee3c5 0%, #1d54df 100%);

@font-face {
  font-family: "Manrope", sans-serif;
  src: url(../fonts/Manrope-VariableFont_wght.ttf) format("swap");
}

@mixin fontFamily {
  font-family: "Manrope", sans-serif !important;
}

@mixin greydisabled {
  background-color: #d8d8d8 !important;
  border: #d8d8d8 !important;
  color: #8d8d8d;
}

html,
body {
  height: 100%;
  @include fontFamily;
}

body {
  margin: 0;

  background: $body;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  @include fontFamily;
}

app-navbar {
  @include fontFamily;

  .main-div {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 255px;
    max-width: 100%;
    overflow-y: auto;

    mat-expansion-panel-header {
      .mat-expansion-indicator {
        display: none;
      }
    }

    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

app-header {
  .main {
    padding: 25px;
    position: fixed;
    right: 0;
    // left: 255px;
    // display: flex;
    // justify-content: space-between;
    height: 100px;

    // align-items: center;
    .last-item {
      display: flex;
      align-items: center;
      gap: 14px;
      // width: 250px;
      //justify-content: space-around;
    }
  }
}

.text-color-primary {
  color: $blue;
}

.header-text {
  position: fixed;
  left: 255px;
  @media (max-width: 768px) {
    left: 0px;
  }
  height: 50px;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  align-items: center;
  margin: 20px 20px;
}

.page-container {
  left: 255px;
  top: 85px;
  position: fixed;
  padding: 25px;
  right: 0;
  bottom: 0;
  @media (max-width: 768px) {
    left: 0px;
  }
  table {
    td {
      color: $black;
      font-size: 14px;
    }

    h3 {
      color: $black !important;
    }
  }

  overflow: auto;

  .for_messages {
    .mat-tab-body-content {
      overflow-x: hidden;
    }
  }

  .subHeader_text {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 500;
  }

  .bottom-line {
    margin-top: 0px !important;
  }
}

otp {
  &.otpinput {
    align-content: center;
    display: inline-flex;
  }

  .otp-container {
    input.otp-input {
      border: none;
      border-bottom: 0.5px solid $lightGrey;
      border-radius: 0;
      font-size: 24px;
      color: $indigo;
      font-weight: bold;

      &:focus {
        outline: 0;
      }
    }

    div {
      margin-left: -10px;
      margin-top: 25px;
      text-align: left;
    }

    .btn {
      font-size: 12px;
      &:not(:disabled) {
        color: $blue;
      }
      span {
        color: $blue;
      }
    }
  }
  &.otpVerifcation { 
    .otp-container {
      input.otp-input {
        font-size: 18px;
        background: transparent;
      }
      div {
        text-align: center;
      }
    }
  }
}

.submit-btn {
  .mat-button-wrapper {
    width: 100%;
  }
}
//for transact on behalf of customrer page
.forTransact {
  otp {
    .submit-btn {
      text-align: end;
    }

    .otp-container {
      div {
        margin-top: 25px;
        margin-left: -104px;
        text-align: center;
      }
    }

    button {
      text-align: end;
    }
  }
}

// login  styles
.main-container {
  background-image: url("/assets/images/loginbackground.svg");
  background-size: cover;
  text-align: center;
  height: 100%;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-attachment: fixed;
  background-position: 100% 0;
}

.logo-container {
  img {
    width: 100%;
    max-width: 250px;
    // height: 132px;
    height: 70px;
    mix-blend-mode: multiply;
    object-fit: contain;
  }
}

.text-newBlack {
  color: #55555a;
}

.login_form {
  margin: 15px;
  // margin-left: 321px;
  // margin-top: 181px;
  // max-width: 438px;
  // max-height: 533px;
  padding: 32px 24px 40px;
  background: #ffffff;
  box-shadow: 0px 0px 44px -5px rgba(6, 16, 88, 0.13);
  border-radius: 16px;
  max-width: 438px;

  mat-form-field {
    width: 100%;
    margin-bottom: 16px;

    small {
      font-size: 10px;
      position: absolute;
      left: 0;
      bottom: -15px;
    }
  }

  .small-head {
    // font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    bottom: 40px;
    display: block;
    margin-bottom: 40px;
    color: $indigo;
  }

  // .radio-button {
  //   display: block;
  // }

  h3 {
    color: #012c79;
    margin: 0px 10px;
    // font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 16px;
  }

  .mob-num {
    margin-top: 34px;
  }

  .login_email {
    // font-family: "manrope", Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #181636;

    .mat-form-field-underline {
      background-color: #a3a2af;
    }
  }

  .login_password {
    // font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #181636;

    .mat-form-field-underline {
      background-color: #a3a2af;
    }
  }

  .login_confirm_password {
    // font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #181636;

    .mat-form-field-underline {
      background-color: #a3a2af;
    }
  }

  .password_toggler {
    cursor: pointer;
  }

  .login_passforgot {
    // font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $blue;
  }

  .submit-btn {
    // max-width: 390px;
    height: 44px;
    width: 100%;
    margin-top: 38px;
    background: #9bc0f5;
    border-radius: 4px;

    &.active_btn {
      background-color: $blue;
    }
  }
}

.kycModal.modal.show .modal-dialog {
  min-width: 360px;
}

ngb-modal-backdrop.modal-backdrop,
ngb-modal-window.modal {
  z-index: 10 !important;
}

.mat-elevation-z8 {
  box-shadow: 0px 0px 21px -10px rgb(6 16 88 / 13%) !important;
  border-radius: 21px;

  .mat-table {
    background-color: #ffffff !important;
    border-radius: 21px !important;
  }

  table {
    .mat-form-field-underline {
      display: none;

      .w-100 {
        background-color: #ffffff;
      }
    }

    th {
      font-size: 12px;
      color: #999999;
    }
  }
}

.page-container {
  .filter {
    // border: 1px solid $blue;
    .mat-form-field-outline {
      color: $blue;
    }

    mat-select {
      padding-left: 24px;
    }

    img {
      width: 18px;
      height: 18px;
    }

    // .mat-form-field-flex{
    //   position: initial !important;
    // }

    .mat-form-field-label {
      color: #181636 !important;
    }
  }

  .filter-date {
    // border: 1px solid $blue;
    width: 148px;

    .mat-form-field-outline {
      color: $blue;
    }

    mat-select {
      padding-left: 24px;
    }
  }

  .add_info {
    a {
      display: flex !important;
      align-items: center !important;
      color: $blue !important;
      font-size: 12px;
    }

    img {
      margin-right: 7px !important;
    }
  }

  .datepicker {
    // border: 1px solid $blue;
    .mat-form-field-outline {
      color: $blue;
    }
  }

  ::ng-deep .mat-form-field-outline-start {
    border-radius: 4px 0 0 4px !important;
    min-width: 4px !important;
  }

  ::ng-deep .mat-form-field-outline-end {
    border-radius: 0 4px 4px 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.75em 0 0.75em 0;
    max-height: 48px;
    min-width: 148px;
  }

  .filter {
    min-width: 150px;
    margin-right: 12px;
  }

  .search {
    width: 312px;

    .mat-form-field-outline {
      background: #ffffff;
      box-shadow: 0px 0px 21px -10px rgba(6, 16, 88, 0.13);
      border-radius: 8px;
      color: transparent;
    }

    &.mat-focused,
    &.mat-form-field-should-float {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        border-color: $blue;
      }
    }

    .mat-form-field-prefix {
      width: 40px;
      text-align: center;
      top: -5px;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .filter,
  .search {
    .dateFilter {
      .mat-form-field-wrapper {
        width: 200px;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-label-wrapper {
      top: -16px;
    }
  }

  .mr-40 {
    margin-top: 40px;
  }

  .mr-64 {
    margin-right: 64px;
  }

  .mr-6 {
    margin-right: 6px;
  }

  mat-form-field {
    // width: 148px;
    // height: 44px;

    // box-sizing: border-box;
    border-radius: 4px;

    mat-label {
      // margin-right: 47px;
      mat-icon {
        // width: 18px;
        // height: 18px;
        margin-left: 28px;
        margin-right: 14px;
      }
    }
  }

  .main-tabs {
    .mat-tab-labels {
      background: #f2f5fa;
      border-radius: 16px 16px 0 0;
    }
  }

  .sub-tabs {
    .mat-tab-labels {
      background: transparent;
      border-radius: 0;
    }
  }

  .expand_more {
    width: 200px;
    display: flex;
    justify-content: space-between;

    mat-icon {
      color: #230b34;
      border: 1px solid #230b34;
      border-radius: 50%;
      vertical-align: bottom;
    }
  }

  .main-section {
    background: #ffffff;
    box-shadow: 0px 0px 21px -10px rgba(6, 16, 88, 0.13);
    border-radius: 16px;

    .mat-table {
      box-shadow: none;
    }
  }

  .onBoard {
    margin-left: 255px;
  }

  .chatinput {
    width: 40%;
    width: 602px;

    .mat-form-field-outline {
      background: #ffffff;
      padding-left: 10px;
    }
  }

  .page-header-message {
    position: relative;
    font-size: 12px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      border: 1px solid #e4e4e4;
      right: 0;
      left: 25%;
      top: 50%;
      z-index: -1;
    }

    &::after {
      content: "";
      position: absolute;
      border: 1px solid #e4e4e4;
      right: 25%;
      left: 0;
      top: 50%;
      z-index: -1;
    }

    span {
      background: #fbfdff;
      display: inline-block;
      padding: 0 10px;
    }
  }

  .cards-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 15px;
    justify-content: space-between;

    &.horizontalView {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }
}

//text ellipsis
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// font sizes
.fs-12 {
  font-size: 12px;
}

.fs-20 {
  font-size: 20px;
}

.fs-32 {
  font-size: 32px;
}

.fs-40 {
  font-size: 40px;
}

.fs-14 {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.mt-13-5 {
  margin-top: 13.5px;
}

// text colors and bg colors
.text-blue {
  color: $blue;
}

.text-pale-blue {
  color: $pale-blue;
}

.text-aqua {
  color: $aqua;
}

.text-pale-aqua {
  color: $pale-aqua;
}

.text-indigo {
  color: $indigo;
}

.text-chrome {
  color: $chrome;
}

.text-positive {
  color: $positive;
}

.text-negative {
  color: $negative;
}

.text-form-label {
  color: #6a6973;
}

// background colors
.bg-blue {
  background-color: $blue;
}

.bg-lightBlue {
  background-color: #e6effc;
}

.bg-pale-blue {
  background-color: $pale-blue;
}

.bg-aqua {
  background-color: $aqua;
}

.bg-pale-aqua {
  background-color: $pale-aqua;
}

.bg-indigo {
  background-color: $indigo;
}

.bg-chrome {
  background-color: $chrome;
}

.bg-positive {
  background-color: $positive;
}

.bg-negative {
  background-color: $negative;
}

.bg-blue-linear-gradient {
  background-color: $blue-linear-gradiant;
}

.margin-bottom-41 {
  margin-bottom: 41px;
}

.ml-18 {
  margin-left: 18px !important;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16px {
  margin-right: 16px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-51 {
  margin-top: 51px;
}

.mt-24 {
  margin-top: 24px !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400;
}

.mt-28 {
  margin-top: 28px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-12 {
  margin-bottom: 12px;
}

.ml-13 {
  margin-left: 13px;
}

.radius-8 {
  border-radius: 8px;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.custom-box-shadow {
  box-shadow: 0px 0px 21px -10px rgb(6 16 88 / 13%);
}

.custom-border-radius {
  border-radius: 16px;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.custom-bg-lightGrey {
  background-color: #f8f8f9;
}

.custom-text-blue {
  background-color: $blue !important;
}

.custom-text-center {
  text-align: center;
}

.btn-grey {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.w-24 {
  width: 24px;
}

.h-24 {
  height: 24px;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.mb-5 {
  // margin-bottom: 5px !important;
}

.pt-16 {
  padding-top: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.ml-24 {
  margin-left: 24px;
}

.custom-checked {
  width: 31.71px;
  height: 41.51px;
  mix-blend-mode: darken;
}

.custom-check-before {
  width: 31.71px;
  height: 41.51px;
  mix-blend-mode: color-burn;
}

.custom-button-details {
  padding-top: 12px;
  padding-bottom: 13px;
  background-color: #0461e5 !important;
  border: #0461e5 !important;
  color: #ffffff;
  font-size: 14px;

  &:disabled {
    @include greydisabled();
  }
}

// button styles
.button-primary-outline {
  padding-top: 12px;
  padding-bottom: 13px;
  border: 1px solid $blue;
  color: $blue;
  font-size: 14px;
}

@mixin inputcustom {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  font-size: 24px;
  font-weight: 700;
  color: #181636;
  text-align: center;
  margin-left: 20px;

  &:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
  }
}

.custom-input-details {
  width: 100px;
  @include inputcustom;
}

.paginator-top {
  .mat-paginator-container {
    justify-content: flex-start !important;
  }

  .mat-paginator-range-actions {
    display: none;
  }
}

.paginator-bottom {
  .mat-paginator-page-size {
    display: none !important;
  }
}

.custom-input-details-small {
  width: 60px;

  @include inputcustom;
  margin-left: 0px;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 99999 !important;
}

.custom-container .mat-dialog-container {
  border-radius: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}

.profile_page {
  .mat-tab-body-content {
    padding: 0 24px;
    height: 100%;
    overflow: hidden;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label mat-label {
    color: #6a6973;
  }
}

.va-text-bottom {
  vertical-align: text-bottom;
}

.plusIcon {
  color: $blue;
  font-size: 16px;
  border: 2px solid $blue;
  padding: 2px 0px 0px 2px;
  border-radius: 7px;
  vertical-align: middle;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}

.task-tabs {
  .mat-tab-label.mat-tab-disabled {
    margin-left: auto;
  }
}

.distributor-popup {
  mat-radio-button {
    margin-top: 13px;
    margin-right: 23px;
  }

  .long-text-field {
    .mat-form-field-infix {
      width: 430px;
    }
  }
}

.popup-sideline-inside {
  &::before {
    z-index: 0 !important;
  }

  span {
    font-size: 14px;
    position: relative;

    &.fs-20 {
      font-size: 20px;
    }
  }
}

.page-header {
  position: relative;
  font-size: 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::before {
    content: "";
    position: absolute;
    border: 1px solid #e4e4e4;
    right: 0;
    left: 0;
    top: 50%;
    z-index: -1;
    margin: 0 30px;
  }

  &.noMargin::before {
    margin: 0;
  }

  span {
    background: #fbfdff;
    display: inline-block;
    padding-right: 10px;
    padding-left: 1px;
  }

  .view_all {
    float: right;
    background: #fbfdff;
    padding-left: 10px;
  }
}

/* messages component chat button center */
.newcard {
  button {
    span {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/* cms-module button center changes*/
.button-cms {
  span {
    margin-top: -3px;
  }
}

.bedCrumbactive {
  color: black;
  font-size: 16px;
  font-weight: 500;
}

xng-breadcrumb {
  .xng-breadcrumb-root {
    padding: 15px 16px;
    border-radius: 4px;
    background-color: #e7f1f1;
    margin-bottom: 1rem !important;

    .xng-breadcrumb-trail {
      margin-bottom: 0px !important;
      color: $blue !important;
      font-size: 18px;
      font-weight: 500;
    }

    .xng-breadcrumb-link {
      font-size: 18px;
    }

    .xng-breadcrumb-item {
      .xng-breadcrumb-link {
        span {
          position: relative;
          bottom: 0px;
          margin-left: 4px;
        }
      }
    }
  }
}

.cardCustomerDetails {
  border: none;
  border-radius: 14px;
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 10%);
  min-height: 400px;
  margin-bottom: 5px;

  .cardBody {
    padding: 0px 0px 0px 0px;
  }
}

.bedcrumbItem {
  position: relative;
  bottom: 1px;
  margin-right: 4px;
}

.validatin-messages {
  text-align: center;
  margin-top: 15px;
  color: red;
}

.custom-panel {
  // background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  // background-color: #ffffff;
  .panel-header {
    background-color: #ffffff;
    min-height: 49px;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #a3a2af;

    label {
      width: 52px;
      text-align: center;
      cursor: pointer;
      margin: 0;
    }
  }
}

.search-filters {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search {
    width: 100%;
    margin-right: 12px;
    position: relative;

    .icon {
      position: absolute;
      width: 44px;
      justify-content: center;
      display: flex;
      align-items: center;
      height: 44px;
      z-index: 1;
    }
  }

  input {
    background: #ffffff;
    opacity: 0.8;
    border-radius: 8px;
    width: 100%;
    height: 44px;
    padding-left: 44px;
  }

  button {
    width: 44px;
    height: 44px;
    padding: 12px;
    border-radius: 8px;
  }
}

.custom_shadow {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.header-links {
  padding: 12px 24px;

  label {
    color: #000;
    cursor: pointer;
    padding-right: 16px;

    &.active {
      color: $grey;
    }
  }
}

.products {
  padding: 12px 24px 24px;
  margin-bottom: 10px;

  .card {
    background: #ffffff;
    border-radius: 15px;
    margin-bottom: 12px;

    .top {
      padding: 16px;
      border-bottom: 1px solid #d1d0d7;
    }

    .bottom {
      //padding: 8px 16px;
      padding: 8px 15px 13px 16px;
    }

    header {
      //margin-bottom: 5px;
    }

    .imageStyle {
      height: 50px;
      width: 50px;
    }

    label {
      font-size: 12px;
    }
  }

  .btn {
    background: $blue;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    width: 100px;
  }

  .favorite {
    cursor: pointer;

    &:hover,
    .fill {
      color: #2feace;
    }
  }

  .invite-items {
    label {
      color: #55555a;
      margin: 0;
    }
  }
}

.text-grey {
  color: $grey;
}

.fs-10 {
  font-size: 10px;
}

.mw-180 {
  max-width: 180px;
}

.text-black {
  color: $black;
}

.text-green {
  color: #00b871;
}

.text-red {
  color: #ea3636;
}

.text-white {
  color: white;
}

.cus-flex-gap {
  gap: 72px;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.custom-card-shadow {
  box-shadow: 0px 2px 5px rgb(0 0 0 / 13%);
}

.custom-mb-3 {
  margin-bottom: 3px !important;
}

.custom-cursor {
  cursor: pointer;
}

.ml-11 {
  margin-left: 11px;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: $blue;
}

.mb-15 {
  margin-bottom: 15px;
}

.fs-9 {
  font-size: 9px;
}

.mr-20 {
  margin-right: 20px;
}

.text-performer {
  color: #45391b;
}

.mr-8px {
  margin-right: 8px !important;
}

.custom-text-capitalize {
  text-transform: capitalize;
}

.mt-8 {
  margin-top: 8px !important;
}

.mr-8px {
  margin-right: 8px !important;
}

.mr-10 {
  margin-right: 10px;
}

.text-deepBlack {
  color: #000000;
}

.mt-15px {
  margin-top: 15px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.ml-0px {
  margin-left: 0px !important;
}

.mr-0px {
  margin-right: 0px !important;
}

.mt-5px {
  margin-top: 5px;
}

.mr-15px {
  margin-right: 15px !important;
}

.mr-7px {
  margin-right: 7px !important;
}

.mt-28px {
  margin-top: 28px;
}

.common-bottom-line {
  border-bottom: 3px solid #f0f0f2;
}

.mr-24px {
  margin-right: 24px !important;
}

.ml-24px {
  margin-left: 24px !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.pro-custom-panel {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  // background-color: transparent;
  .panel-header {
    background-color: #ffffff;
    min-height: 49px;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #a3a2af;

    label {
      width: 52px;
      text-align: center;
      cursor: pointer;
      margin: 0;
    }
  }
}

.pro-products {
  padding: 12px 24px 24px;
  margin-bottom: 10px;

  .card {
    background: #ffffff !important;
    border-radius: 15px;
    margin-bottom: 12px;

    .top {
      padding: 16px;
    }

    .custom-bottom-line {
      border-bottom: 1px solid #d1d0d7;
    }

    .bottom {
      //padding: 8px 16px;
      padding: 8px 15px 13px 16px;
    }

    // header {
    //   //margin-bottom: 5px;
    // }
    .imageStyle {
      height: 50px;
      width: 50px;
    }

    label {
      font-size: 12px;
    }

    .tagsDetails {
      background-color: #f9f4e0;
      padding: 6px 7px 6px 7px;
      border-radius: 20px;
    }
  }

  .btn {
    background: $blue;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    width: 100px;

    &-outline {
      border: 1px solid $blue;
      background: transparent;
      color: $blue;
    }
  }

  .favorite {
    cursor: pointer;

    &:hover,
    .fill {
      color: #2feace;
    }
  }

  .invite-items {
    label {
      color: #55555a;
      margin: 0;
    }
  }
}

.modal-content {
  border-radius: 16px;
}

.task-modal {
  min-width: 636px;
  .mat-dialog-container {
    padding: 0;
  }
}

.no-padding-task-modal {
  .mat-dialog-container {
    padding: 0;
  }
}

.admin-task-modal {
  min-width: 923px;
}

.distributor-modal {
  min-width: 938px;

  .mat-dialog-container {
    padding: 0;
    max-width: 938px;

    .add_distributor_popup {
      mat-form-field {
        width: 100%;

        .mat-form-field-wrapper {
          padding-bottom: 1em;
        }
      }

      .file-input {
        display: none;
      }

      .upload-btn {
        border: none;
        background-color: rgb(255, 255, 255);
        font-size: 14px;

        color: #0461e5;
      }

      .file-upload {
        margin-top: 12px;
        margin-left: -70px;
        position: absolute;
        display: inline;

        button:disabled {
          opacity: 0.5;
        }
      }

      input.file-input {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 150px;
      }

      mat-password-strength-info mat-card {
        justify-content: start;
      }

      ::ng-deep .add_distributor_stepper .mat-horizontal-stepper-header {
        // pointer-events: none !important;
      }

      .popup-header {
        padding: 32px 32px 16px;
      }

      .mat-stepper-horizontal {
        border-radius: 16px;
      }

      .modal-footer {
        min-height: 72px;
        border-top: 1px solid #e9e9e9;
        margin: 24px -32px -16px;
        padding: 0 32px;
        align-items: center;
        display: flex;
        justify-content: flex-end;

        button {
          min-width: 110px;
        }

        .mat-stroked-button {
          color: #0461e5;
          border: 1px solid #0461e5;
        }
      }

      form .page-header {
        label {
          margin-bottom: 0;
          border-radius: 50%;
          width: 24px;
          height: 24px;

          .mat-icon {
            color: #00b871;
          }

          display: none;
        }

        &::before {
          margin: 0;
        }

        &.uploaded {
          label {
            display: block;
          }

          &::before {
            margin: 0 30px;
          }
        }
      }

      .image_label {
        mat-chip {
          background: #0461e5;
          color: #ffffff;

          label {
            margin-top: 1px;
            margin-right: 2px;
            cursor: pointer;
            color: #ffffff;
          }
        }
      }

      .mat-flat-button.mat-primary,
      .mat-raised-button.mat-primary,
      .mat-fab.mat-primary,
      .mat-mini-fab.mat-primary {
        background-color: #0461e5;
        color: #ffffff;

        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}

input.mat-input-element,
.mat-select-value {
  line-height: 18px;
  color: $indigo;
}

.mat-radio-button {
  color: $light;

  &.mat-radio-checked {
    color: $indigo;
  }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $indigo !important;

  &:focus {
    box-shadow: none;
  }

  /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $indigo !important;
  /*outer ring color change*/
}

.clear-button {
  float: right;
  padding: 3px 25px 3px 25px;
  font-weight: 400;
  background-color: $blue;
  font-size: 14px;
}
mat-checkbox {
  .mat-checkbox {
    height: 18px;
    width: 18px;
  }

  .mat-checkbox-inner-container {
    height: 18px;
    width: 18px;
    margin-top: 6px;
  }
}

.pr-30 {
  padding-right: 32px;
}

.pl-0 {
  padding-left: 0px;
}

.p-0 {
  padding: 0px;
}

.mb-25 {
  margin-bottom: 25px;
}

.custome-cursor {
  cursor: pointer;
}

.pr-0 {
  padding-right: 0px;
}

.mr-29 {
  margin-right: 29px;
}

.mtb-33 {
  margin-top: 33px;
  margin-bottom: 33px;
}

.padding-modal {
  padding: 32px 31px 31px 32px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: transparent !important;

  .mat-checkbox-checkmark {
    top: 0px;
    left: 3px !important;
    right: 0;
    bottom: 0px;
    position: absolute;
    width: 13.3px !important;
    height: 16.5px !important;
  }

  &::after {
    .mat-checkbox-frame {
      border-color: black !important;
    }
  }
}

.mat-checkbox.customCheckbox .mat-checkbox-checkmark-path {
  stroke: #a3a2af !important;
  stroke-width: 4.133333px !important;
  width: 5px;
}

.mat-checkbox.customCheckbox {
  .mat-checkbox-frame {
    border-color: #a3a2af;
    border-radius: 5px;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-frame {
      border-color: #181636;
    }
  }
}

.page-container .filter .mat-form-field-prefix {
  top: -3px !important;
  width: 25px;
}

/* Mat tabs custom styles*/
.tabs {
  .mat-tab-labels {
    min-height: 65px;
    align-items: end;

    .mat-tab-label {
      font-size: 20px;
      color: rgba(85, 85, 90, 0.4);

      &-active {
        color: $blue;
        pointer-events: none;
        font-weight: 600;
      }
    }
  }
}

.btnInvest {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 280px;
  max-width: 280px;
  background: #0461e5;
  border-radius: 4px;
  height: 44px;
  color: white;
  border: none !important;
}

.mat-tab-labels .mat-tab-label {
  opacity: 1;
  padding: 0 4px;
  margin: 0 12px;
  min-width: auto;
  color: #777777;
  font-weight: normal;

  &-active {
    color: $blue;
    pointer-events: none;
    font-weight: 600;
  }
}

.mat-tab-links .mat-tab-link {
  color: #777777;
  font-size: 20px;
  opacity: 1;
  padding: 0 4px;
  margin: 0 24px;
  min-width: auto;

  &.mat-tab-label-active {
    color: $blue;
    pointer-events: none;
  }

  a {
    text-decoration: none;
  }
}

.noMarginLabel {
  .mat-tab-links .mat-tab-link,
  .mat-tab-labels .mat-tab-label {
    &:first-child {
      margin: 0;
    }
  }
}

.distributor-dashboard {
  .mat-tab-link,
  .mat-tab-label {
    &:first-child {
      margin: 0 24px;
    }
  }
}

.mat-menu-item:last-child {
  border-bottom-width: 0 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $blue;
  border-radius: 30px;
  height: 3px;
}

/* Mat table custom styles */
.mat-table {
  border-radius: 16px;
  box-shadow: none;
  background-color: transparent;

  tr:last-child td {
    border: none;
  }

  mat-footer-row,
  mat-header-row,
  mat-row,
  td.mat-cell,
  td.mat-footer-cell,
  th.mat-header-cell {
    border-color: #e9e9e9;
  }
}

.mat-paginator {
  background: transparent;
}

.table-box {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 21px -10px rgba(6, 16, 88, 0.13);

  .custom-card {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 0 20px;
    min-height: 163px;

    .card-info {
      p {
        font-size: 14px;

        &:first-child {
          color: #55555a;
        }

        &.fs-22 {
          font-size: 22px;
        }
      }
    }

    p:last-child {
      color: #55555a;
      margin-top: 10px;
      margin-bottom: 0px;
    }

    round-progress {
      min-width: 100px;
      min-height: 100px;
    }

    .bottom_text {
      position: absolute;
      bottom: 5px;
    }
  }

  .small-cards {
    .bg-yellow {
      background-color: #f9f4e0;
    }

    .bg-pink {
      background-color: #ffe3e3;
    }

    .card-small {
      border-radius: 10px;
      min-height: 74px;
      // max-width: 137px;
      padding: 10px 20px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.pr-35 {
  padding-right: 35px;
}

.mat-menu-panel {
  border-radius: 16px !important;
  box-shadow: 0px 4px 9px 0px rgba(151, 151, 151, 0.19);
}

.transact-modal {
  max-width: 360px;
}

.border-bottom {
  border-bottom: 1px solid #ebebeb;
}

::-webkit-scrollbar {
  width: 4px;
}
:hover::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}

.mr-1rem {
  margin-right: 1rem !important;
}

.ml-1rem {
  margin-left: 1rem !important;
}

.bg-grey {
  background-color: #f8f8f9 !important;
}

.custom-br {
  border-radius: 16px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-1 {
  // margin-bottom: 1px !important;
}

.custom-border-none {
  border: none !important;
}

.mb-8px {
  margin-bottom: 8px !important;
}

.mt-18 {
  margin-top: 18px;
}

.fs-8 {
  font-size: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-16px {
  margin-top: 16px !important;
}

.mr-18 {
  margin-right: 18px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.ml-30 {
  margin-left: 30px;
}

.mr-25 {
  margin-right: 25px;
}

.mt-32 {
  margin-top: 32px;
}

.custom-display {
  display: inline-block;
}

.mr-12px {
  margin-right: 12px !important;
}

.text-paleGrey {
  color: #8f92a1;
}

.bg-white {
  background-color: #ffffff;
}

.text-custom-black {
  color: #0e0e0e;
}

.br-8 {
  border-radius: 8px;
}

.ml-42 {
  margin-left: 42px;
}

.br-4 {
  border-radius: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mt-10 {
  margin-top: 10px !important;
}

.fw-700 {
  font-weight: 700;
}

.mt-9 {
  margin-top: 9px;
}

.mb-2 {
  // margin-bottom: 2px !important;
}

.mr-4px {
  margin-right: 4px;
}

.fs-11 {
  font-size: 11px !important;
}

.fw-600 {
  font-weight: 600;
}

.text-darkenGrey {
  color: #747386;
}

.mt-14 {
  margin-top: 14px;
}

.mb-4 {
  // margin-bottom: 4px !important;
}

.height-40 {
  height: 40px;
}

.text-lightGrey {
  color: $lightGrey;
}

.mr-15px {
  margin-right: 15px !important;
}

.mr-7px {
  margin-right: 7px !important;
}

.text-light {
  color: $light !important;
}

.align-center {
  text-align: center;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-11 {
  margin-right: 11px;
}

.mr-9 {
  margin-right: 9px;
}

.pb-20 {
  padding-bottom: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-24 {
  margin-bottom: 25px !important;
}

.custom-box-shadow-card {
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 10%);
}

.border-bottom-custom {
  border-bottom: 3px solid #f0f0f2;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.custom-align-text {
  text-align: center;
}

.mt-37 {
  margin-top: 37px;
}

.ml-3px {
  margin-left: 3px;
}

.custom-text-lightGrey {
  color: #55555a;
}

.custom-text-transform {
  text-transform: capitalize;
}

.custom-margins-all {
  margin-left: 24px;
  margin-right: 24px;
}

.custom-boxshaddow {
  box-shadow: 0px 2px 60px 0px rgb(0 0 0 / 8%);
}

.custom-position-relative {
  position: relative;
}

.mt-5px {
  margin-top: 5px;
}

.custom-days {
  flex-wrap: wrap;
  gap: 13px;
}

.text-orage {
  color: #f87e24 !important;
}

.erro-amount {
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
}

.ml-14 {
  margin-left: 14px;
}

.custom-border-bottom {
  border-bottom: 1px solid #d1d0d7;
}

.cus-flex-gap-24 {
  gap: 24px;
}

.cus-flex-gap-16 {
  gap: 16px;
}

.cus-flex-gap-12 {
  gap: 12px;
}

.mr-14 {
  margin-right: 14px;
}

.mr-10 {
  margin-right: 10px;
}

.custom-button-buy {
  width: 100% !important;
  padding: 12px !important;
  font-size: 14px !important;
}

.custom-button-sell {
  width: 100% !important;
  padding: 12px !important;
  font-size: 14px !important;
  background: none !important;
  color: #0461e5 !important;
}

.mr-20px {
  margin-right: 20px !important;
}

.custom-gap-40 {
  gap: 40px;
}

.custom-flex-wrap {
  flex-wrap: wrap;
}

.custom-flex-basis {
  flex-basis: 33.333333%;
}

.custom-days-details-disabled {
  padding: 5px;
  background-color: #c4c4c4;
  font-size: 12px;
  font-weight: 600;
  color: #747386;
  border-radius: 4px;
}

.custom-viewInvestment-button {
  background-color: #0461e5;
  display: flex;
  align-items: center;
  padding: 3px 6px 3px 6px;
  border-radius: 25px;
  color: white;
  font-size: 10px;
  font-weight: 500;

  &:active {
    background: #e5e5e5;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
  }
}

.custom-days-details {
  padding: 5px;
  background-color: #e6effc;
  font-size: 12px;
  font-weight: 600;
  color: #747386;
  border-radius: 4px;
  cursor: pointer;
}

.custom-days-details-selected {
  padding: 5px;
  background-color: #0461e5;
  font-size: 12px;
  font-weight: 600;
  color: #fcfcfd;
  border-radius: 4px;
  cursor: pointer;
}

.custom-padding-pause {
  //margin-left:14px;
  //margin-top:20px;
  padding: 20px 14px 10px 14px;
}

.custom-details-pause-resume {
  // flex-wrap: wrap;
  gap: 12px;
  //margin-left: 14px;
}

.custom-noData {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: orange;
}

.noDataAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
}

.bg-paleblue {
  background-color: #e1ecfc;
}

.bg-palered {
  background-color: #ffe3e3;
}

.custom-select-details {
  padding: 24px 25px 40px 20px;

  .custom-card1 {
    .card-body {
      padding: 16px 24.5px 16px 19px;

      .image {
        width: 31.71px;
        height: 41.51px;
        mix-blend-mode: color-burn;
      }
    }
  }
}

.ml-20 {
  margin-left: 20px;
}

.custom-round-investers {
  background-color: #e1ecfc;
  height: 20px;
  width: 20px;
  padding: 14px;
  font-size: 12px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.three-digits {
}

.custom-resume-card {
  background-color: #f8f8f9;
  height: 58px;
  width: 100px;
  border-radius: 4px;
  text-align: center;
}

.custom-flex-gap {
  gap: 8px;
}

.pt-9 {
  padding-top: 9px !important;
}

.text-secdtext {
  color: #55555a;
}

.custom-round-checkmark-image {
  width: 21.6px;
  height: 21.6px;
  position: absolute;
  right: 0px;
}

.custom-Header {
  height: 49px;
  border-bottom: 1px solid #a3a2af;
  display: flex;
  align-items: center;

  .custom-Name {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }

  .backArrowImage {
    margin-right: 16px;
    margin-left: 24px;
  }
}

.portfolio {
  .mainContent {
    padding-left: 37px;
    padding-right: 39px;
    padding-top: 24px;

    .green {
      width: 20%;
      min-width: 55px;
      height: 3px;
      background-color: #7e940a;
      display: inline-block;

      &:nth-child(2) {
        background-color: #a8bd39;
      }

      &:nth-child(3) {
        background-color: #e7d10c;
      }

      &:nth-child(4) {
        background-color: #f22f35;
      }

      &:nth-child(5) {
        background-color: #990408;
      }
    }
  }

  .first-block {
    span {
      font-weight: normal;
      font-size: 8px;
      line-height: 140%;
    }
  }

  .secondBlock {
    margin-top: 14px;

    .conservative {
      display: inline-grid;
      padding-left: 4px;

      .custom-span {
        background-color: #e1ecfc;
        height: 31px;
        width: 32px;
        border-radius: 20px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
      }

      .custom-image {
        position: absolute;
        bottom: 8px;
        left: 9px;
        height: 17.1px;
        width: 16px;
      }

      img {
        place-self: center;
      }

      h3 {
        padding-top: 8px;
        text-align: center;
        font-weight: normal;
        font-size: 8px;
        line-height: 140%;
        text-align: center;
        color: #747171;
      }
    }
  }

  .thirdSection {
    .mainHeader {
      display: inline-flex;
      margin-top: 12px;

      h3 {
        margin: auto;
        //margin-left: 15px;
        margin-left: 9px;
        //font-weight: bold;
        font-weight: 700;
        font-size: 10px;
        line-height: 140%;
      }
    }

    .subHeading {
      margin-top: 6px;

      h2 {
        //font-weight: bold;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 2px;
      }

      p {
        font-weight: normal;
        font-size: 10px;
        line-height: 140%;
        color: #55555a;
      }
    }
  }

  .chartSection {
    //width: 280px;
    height: 229px;
    background-color: #f8f8f9;
    border-radius: 8px;
  }

  .lastSection {
    display: inline-flex;
    margin-top: 42px;

    h3 {
      margin: auto;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-right: 8px;
    }
  }

  .insideSection {
    margin-top: 16px;
    display: block;

    .headtext {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #55555a;
      margin-bottom: 4px;

      span {
        margin-left: 4px;
      }
    }

    .bottomHeadtext {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #00b871;
    }

    .text2 {
      color: #ea3636;
    }

    .block {
      display: inline-block;
    }

    .block2 {
      float: right;
    }
  }

  .cardBlock {
    // display: inline-flex;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    text-align: -webkit-center;
    flex-wrap: wrap;
    .smallBlock1,
    .smallBlock2,
    .smallBlock3 {
      width: 78px;
      // height: 58px;
      background-color: #f8f8f9;
      border-radius: 4px;
    }

    .smallBlock1 {
      text-align: center;
      float: left;
    }

    .smallBlock2 {
      margin: auto;
      //   text-align: end;
    }

    .smallBlock3 {
      float: right;
      //   margin: auto;
    }

    .smallHead {
      font-weight: bold;
      font-size: 14px;
      line-height: 140%;
      color: #0e0e0e;
      margin: revert;
      margin-bottom: 0px;
    }

    .smallHead2 {
      font-weight: normal;
      font-size: 12px;
      line-height: 140%;
      text-align: center;
      color: #181636;
    }
  }

  .btnInvest {
    margin-top: 20px;
    margin-bottom: 40px;
    background: #0461e5;
    border-radius: 4px;
    height: 44px;
    color: white;
  }
}

.active-span {
  background-color: #0461e5 !important;
}

.active-image {
  filter: brightness(0) invert(1) !important;
}

// The all goals styles

.allGoals {
  mat-accordion {
    background: transparent;

    mat-expansion-panel {
      background: rgba(255, 255, 255, 0.3);
      align-items: center;
      box-shadow: none !important;

      mat-expansion-indicator {
        display: none;
      }

      .mat-expansion-panel-header {
        padding: 16px 18px 16px 16px;
      }

      .image-logo {
        padding-top: 38px;
        padding-bottom: 51px;
      }

      .arrow {
        // max-width: 72.48px;
        //height: 18px;
        //mix-blend-mode: difference;
        transform: rotate(180deg);
      }

      a {
        display: flex;
        text-decoration: none;
        color: #000000;
        letter-spacing: 0.2px;
        width: 255px;
        padding-left: 17px;
        padding-right: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: space-between;

        &:first-child {
          border-top: 1px solid rgba(255, 255, 255, 0.3);
        }

        // img{
        //   max-width: 72.48px;
        //   height: 24px;
        //   mix-blend-mode: difference;
        //   transform: rotate(-90deg);
        // }
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }

      mat-panel-title {
        display: flex !important;
        justify-content: space-between !important;
        font-size: 12px;
        //color: #45391B;
        align-items: center !important;
        height: 100px;

        &:hover {
          background-color: transparent;
        }
      }

      mat-panel-title:hover {
        color: #000000;
      }

      &.mat-expanded img {
        transform: rotate(0deg);
      }

      &.mat-expansion-panel-spacing {
        margin: 0;
      }

      .mat-expansion-panel-header {
        height: auto !important;
        padding-bottom: 0px !important;

        &:hover {
          background-color: transparent !important;
        }
      }

      .mat-expansion-panel-header.mat-expanded {
        //font-weight: 500;

        .arrow {
          color: #0461e5;
        }
      }

      .mat-expansion-panel-content.mat-expansion-panel-body {
        padding: 0 24px 0px !important;
      }

      .mat-expansion-panel-header-title {
        margin-right: 0px !important;
      }

      .mat-expansion-panel-body {
        background-color: rgba(225, 236, 252, 0.4);
        margin-top: -24px;
        margin-left: 15px;
        margin-right: 29px;
        margin-bottom: 12px;
        padding: 40px 8px 16px !important;
      }
    }
  }

  .card1-card-body {
    padding: 6px 13px 13px 6px;

    .on-track {
      background-color: #e3fffa;
      font-size: 10px;

      border-radius: 20px;
      text-align: center;

      padding: 5px 11px 5px 11px;
      color: #1e9886;

      display: inline-block;
      margin-right: 8px;
      margin-top: 5px;
    }

    .off-track {
      background-color: #ffe3e3;
      font-size: 10px;

      border-radius: 20px;
      text-align: center;

      padding: 5px 11px 5px 11px;
      color: #ea3636;

      display: inline-block;
      margin-right: 8px;
      margin-top: 5px;
    }
  }
}

.mainContent_allgoals {
  // margin-left: 24px;
  // margin-right: 24px;
  //flex-grow: 1;
  .cardRecomentations {
    //max-width: 325px;
    margin-right: 10px;
    min-height: 88px;
    background: #ffffff;
    mix-blend-mode: normal;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    .circle {
      background-color: #96efdf;
      border-radius: 50%;
      display: flex;
      align-self: center;
      position: absolute;
      width: 38px;
      height: 38px;
      margin-left: 18px;

      text-align: center;
    }

    .sideBackground {
      display: flex;

      .insideContent {
        //margin-left: 26px;
        margin-left: 25px;
        margin-bottom: 0px;
        flex-grow: 1;

        h3 {
          //font-weight: bold;
          font-weight: 700;
          font-size: 14px;
          color: #181636;
          line-height: inherit;
          margin-top: 15px;
          //line-height: 19px;
          margin-bottom: 10px;
        }

        p {
          background-color: #e1ecfc;
          width: 100px;
          padding: 3px 5px 3px 7px;
          border-radius: 16px;
          margin-bottom: 18px;
          margin-top: 0px;
          font-weight: 500;
        }
      }

      .plusButton {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }
}

.card-with-border {
  position: relative;

  .custom-border-card {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -21px;
      bottom: 0px;
      height: 110%;
      border-left: 3px dotted #b6b6b6;
      margin-left: -25px;
    }
  }

  .custom-circle {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    background-color: white;
    border: solid 4px #3ee3c5;
    border-radius: 50%;
    position: absolute;
    top: 15px;

    margin-left: -31px;
  }
}

.card-with-border-investers {
  position: relative;

  .custom-border-card-investers {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -21px;
      bottom: 0px;
      height: 109%;
      border-left: 3px dotted #b6b6b6;
      margin-left: -25px;
    }
  }

  .custom-circle-investers {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    background-color: white;
    border: solid 4px #3ee3c5;
    border-radius: 50%;
    position: absolute;
    top: 20px;

    margin-left: -31px;
  }
}

.profile_stepper .mat-horizontal-stepper-header-container {
  display: none !important;
}

.custom-circle-red {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 14px;
  background-color: white;
  border: solid 4px #ea3636;
  border-radius: 50%;
  position: absolute;
  top: 15px;

  margin-left: -31px;
}

.modal-content {
  .mat-flat-button.mat-primary,
  .mat-raised-button.mat-primary,
  .mat-fab.mat-primary,
  .mat-mini-fab.mat-primary {
    background-color: #0461e5;
    color: #ffffff;

    &:disabled {
      opacity: 0.5;
    }
  }

  .mat-stroked-button {
    color: #0461e5;
    border: 1px solid #0461e5;
  }

  button.close {
    &:focus-visible {
      outline: none;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.profile_stepper .mat-horizontal-stepper-header-container {
  display: none !important;
}

.onboard_customer {
  .mat-vertical-content-container {
    margin-left: 0;
  }

  .mat-vertical-content {
    padding: 36px 60px;
  }

  .mat-vertical-stepper-header {
    display: none !important;
  }

  .mat-stepper-vertical-line::before {
    content: none !important;
  }
}

// onboard customer styles
.onboard_menu {
  min-width: 255px;
  margin-top: 35px;

  ul {
    list-style: none;

    li {
      color: #a4a6b3;
      font-size: 12px;
      position: relative;
      padding-bottom: 60px;

      &.active {
        color: #0461e5;

        &::before {
          color: #0461e5;
        }
      }

      &::before {
        position: absolute;
        width: 22px;
        height: 22px;
        left: -30px;
        top: 0;
        background: #ffffff;
        box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.08);
        content: "\25CF";
        border-radius: 50%;
        font-size: 16px;
        text-align: center;
        color: #dadada;
      }

      &.completed {
        pointer-events: none;
      }

      &.completed::before {
        content: "\2713";
        color: #0461e5;
      }

      &::after {
        content: "";
        position: absolute;
        top: 24px;
        height: 98%;
        width: 0;
        border: 2px solid #f0f0f2;
        left: -21px;
        background-color: #f0f0f2;
      }

      &:last-child::after {
        content: none;
      }

      a {
        cursor: pointer;
      }
    }
  }
}

.onboard_customer {
  form {
    mat-form-field {
      width: 270px;

      &.countryCode {
        width: 50px;
        margin-right: 5px;
      }

      &.phone {
        width: 215px;
      }
    }
  }
}

.form-max-width {
  max-width: 636px;
}

.buy_power_icon {
  position: relative;
  top: 7px;
}

@media (min-width: 768px) {
  .login_form {
    width: 438px;
  }
}

// loader styles for vertical stepper
ul li.stepperLoader::before {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid $blue;
  width: 22px;
  height: 22px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  content: "";
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

:host ::ng-deep .mat-input-element::placeholder {
  color: rgb(0, 255, 85) !important;
}

:host ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgb(0, 89, 255) !important;
}

.myPanelClass {
  margin-top: 40px !important;
  overflow: unset !important;
}

.custom-autocomplete {
  overflow: auto;
  max-height: 200px;
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  .view-more {
    position: sticky;
    bottom: 10px;
    z-index: 999;
    text-align: right;
    float: right;
  }
}

.disable circle {
  fill: #c7c7cf;
}

.disable rect {
  fill: #c7c7cf;
}

.enable circle {
  fill: #00b871;
}

.enable rect {
  fill: #ea3636;
}

.disabled_div {
  pointer-events: none;
}

.enable_div {
  pointer-events: all;
}

.opacity-50 {
  opacity: 0.5;
}

.disable-select {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

/*disbaled if date picker*/
// mat-multi-year-view .mat-calendar-body-today:not(.mat-calendar-body-selected),
// mat-year-view .mat-calendar-body-today:not(.mat-calendar-body-selected) {
//   background-color: transparent !important;
//   border-color: transparent !important;
// }

// .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
// .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
//   background-color: transparent !important;
// }

// .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
//   background-color: rgba(63, 81, 181, 0.3) !important;
// }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.disabledContent {
  cursor: none;
  color: darkgrey;
  pointer-events: none;
}

apx-chart svg {
  overflow: visible;
}
.apexcharts-text tspan {
  font-size: 10px;
}
.mat-form-field-label mat-label {
  position: relative;
  top: -2px;
}
.card_tabs{
  .mat-tab-links{
    justify-content: space-around;
  }
  .mat-ink-bar{
    top: 0 !important;
  }
}